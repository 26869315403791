<template>
    <Card class="mb-15 card-custom">
      <template #title>
        <Toolbar>
          <template #start>
          </template>
          <template #end>
            <Button :label="$t('create_new_level')" icon="pi pi-plus" class="p-button-sm p-button-success" @click="toOpenCreateLevel" />
            </template>
      </Toolbar>
      </template>
      <template #content>
            <DataTable :value="levels" 
            :rows="10"
            stripedRows
            responsiveLayout="scroll"
            :loading="loadingFlag" 
            :paginator="true"
            class="p-datatable-sm">
              <!-- <Column field="order" header="№">
              </Column> -->
              <Column field="name" header="№" :styles="{'min-width':'10rem'}">
              </Column>
              <Column field="next_level_amount" :header="$t('amount_to_the_next_level')" :styles="{'min-width':'20rem'}">
                <template #body="slotProps">
                  <div>
                    <span v-for="(value, currency, index) in slotProps.data.next_level_amount" :key="currency">
                      <span v-if="value !== 0">
                        {{ value }} {{ currency }}{{ index < Object.keys(slotProps.data.next_level_amount).length - 1 ? ',' : '' }}
                      </span>
                    </span>
                  </div>
                </template>
              </Column>
              <Column field="reward" :header="$t('reward')" :styles="{'min-width':'50rem'}">
                <template #body="slotProps">
                  <div class="d-flex flex-column">
                    <div v-if="slotProps.data.dep_bonus !== null">
                      <span class="font-weight-bold">{{$t('dep_bonus')}}:
                       </span> 
                       {{formatDepositBonus(slotProps.data.dep_bonus)}}
                      </div> 
                    <div v-if="slotProps.data.free_rounds_bonus !== null">
                      <span class="font-weight-bold">{{$t('free_rounds')}}:</span> 
                    {{formatSpinsBonus(slotProps.data.free_rounds_bonus)}}
                  </div>
                  <span v-else>
                    {{ $t('no_reward') }}
                  </span>
                  </div>
                </template>
              </Column>
              
              <Column :header="$t('actions')" :exportable="false" :styles="{'min-width':'8rem'}">
                <template #body="slotProps">
                    <div class="d-flex align-center">
                      <Button icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-warning mr-2" @click="toOpenEdit(slotProps.data)" />
                      <Button icon="pi pi-trash" class="p-button-sm p-button-rounded p-button-danger" @click="toDeleteLevel(slotProps.data)" />
                  
                    </div>
                  </template>
            </Column>
            </DataTable>
    </template>
    <template #footer>
        <!-- Delete Window -->
<v-dialog
v-model="toDeleteDialog"
max-width="550px"
scrollable
:persistent="formLoading"
content-class="br-16"
>
<v-card>
  <v-card-title class="font-weight-bold flex-column align-start">
    <span>{{ $i18n.t("level_delete") }}</span>
    <span class="c-red">{{ $i18n.t("level_delete_confirm") }}</span>
  </v-card-title>
  <v-card-text class="pb-0 pt-2">
    <v-text-field
      v-model="deleteLevel.name"
      disabled
      outlined
      hide-details
      class="mb-3"
      dense
      color="var(--main-flamingo)"
      :label="$i18n.t('name')"
    >
    </v-text-field>
    <v-text-field
    hide-details
    class="mb-3"
    v-if="deleteLevel.description"
      v-model="deleteLevel.description"
      disabled
      outlined
      dense
      color="var(--main-flamingo)"
      :label="$i18n.t('description')"
    >
    </v-text-field>
    <v-switch
    disabled
          v-model="deleteLevel.is_active"
          hide-details
          :label="$t('active')"
          color="green accent-4" inset
          class="mt-0 ml-1 mb-3 custom-switch "
          ></v-switch>
  </v-card-text>
  <v-card-actions>
      <Button class="p-button-sm p-button-danger fw" @click="confirmDeleteLevel" 
      :disabled="formLoading"
      :loading="formLoading"
      icon="pi pi-trash"
      :label="$t('delete')" />
  </v-card-actions>
</v-card>
</v-dialog>
    </template>
    </Card>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    name: "PlayerLevelsView",
    data() {
      return {
        formLoading: false,
        toDeleteDialog: false,
        loadingFlag: false,
        filters:{
          page: 1,
          size: 50,
        },
        deleteLevel: {},
        fr_bonuses:[],
        dep_bonuses:[],
      };
    },
    async mounted() {
      this.loadingFlag = true;
      if (this.levels.length === 0) {
          await this.getLevels();
          this.holdBonuses();
      }
      this.loadingFlag = false;
    },
    computed: {
      ...mapGetters({
        levels: "levels/getLevels",
        spinsBonuses: 'bonuses/getSpinsAllListBonuses',
        depositBonuses: 'bonuses/getDepositAllListBonuses',
        currencies: 'getFilteredCurrencies',
      }),
    },
    methods: {
      // Get Levels
      async getLevels(){
          await this.$store.dispatch("levels/awaitGetLevels", {filters: this.filters});
      },
      //========================================================================================================================================================
      // Hold bonuses
      async holdBonuses(){
        if (this.depositBonuses.items.length === 0 ) {
            await this.$store.dispatch('bonuses/awaitGetDepositBonuses');
          }
        if (this.spinsBonuses.items.length === 0) {
          await this.$store.dispatch('bonuses/awaitGetSpinsBonuses');
        }
        this.dep_bonuses = [
        { text: this.$t("none"), value: 0 },
        ...this.depositBonuses.items.map((bonus) => ({
          text: this.formatDepositBonus(bonus),
          value: bonus.id,
        })),
      ];
      this.fr_bonuses = [
        { text: this.$t("none"), value: 0 }, // "None"
        ...this.spinsBonuses.items.map((bonus) => ({
          text: this.formatSpinsBonus(bonus),
          value: bonus.id,
        })),
      ];
      },
      //========================================================================================================================================================
      // Level formated bonuses
      formatDepositBonus(bonus) {
              if (!bonus || !bonus.currency_limits) {
                  return;
              }
              const formattedBonus = Object.entries(bonus.currency_limits)
                  .map(([currency, limits]) => ` ${currency}: ${this.$t('min_dep')}: ${limits.min_dep}, ${this.$t('max_dep')}: ${limits.max_dep}`);
              const restOfName = [
                  this.$t('bonus_percent') + ': ' + bonus.bonus_percent,
                  this.$t('wager') + ': ' + bonus.wager,
                  this.$t('type') + ': ' + this.$t(bonus.type),
              ];
              return [...restOfName, ...formattedBonus].join(', ');
      },
      formatSpinsBonus(bonus) {
              if (!bonus || !bonus.currency_bet) {
                  return;
              }
              const formattedBonus = Object.entries(bonus.currency_bet)
                  .filter(([currency, value]) => value !== 0)
                  .map(([currency, value]) => this.$t('bet') + ': ' + value + ' ' + currency);
              const restOfName = [
                  this.$t('game_name') + ': ' + bonus.game_name,
                  this.$t('provider') + ': ' + bonus.provider_name,
                  this.$t('spin') + ': ' + bonus.count,
              ];
              const restOfLast = [
                  this.$t('wager') + ': ' + bonus.wager,
                  this.$t('type') + ': ' + this.$t(bonus.type),
              ];
              return [...restOfName, ...restOfLast, ...formattedBonus].join(', ');
      },
      //========================================================================================================================================================
      // Edit levels
      async confirmDeleteLevel() {
        this.formLoading = true;
        this.loadingFlag = true;
        try {
            await this.$store.dispatch(
              "levels/awaitDeleteLevel",
              this.deleteLevel.id
            );
            this.toDeleteDialog = false;
            this.$toast.add({
                          severity: 'success', summary: this.$t("level_deleted"), life: 4000,
                        })
                        this.getLevels();
        } catch (error) {
            this.$toast.add({
            severity: 'error', summary: this.$t("just_error"), life: 4000,
          })
        } finally{
            this.formLoading = false;
            this.loadingFlag = false;
        }
      },
      toDeleteLevel(level) {
        this.toDeleteDialog = true;
        this.deleteLevel = level;
      },
      toOpenCreateLevel() {
      this.$router.push('/player-levels/create');
    },
    toOpenEdit(level) {
        this.$router.push(`/player-levels/edit/${level.id}`);
    }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>
  